import { Unsubscribe } from "firebase/firestore";
import CashReturn from "../entities/cashReturn.entity";
import FirestorePipe from "../utils/pipe";

class CashReturnDB extends FirestorePipe {

  public static readonly colName = 'CashReturn'

  constructor(idEstablishment: number, idEvent: number) {
    super(CashReturnDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<CashReturn[]> {
    return this._getAll<CashReturn>();
  }
  public get(uid: string): Promise<CashReturn> {
    return this._get(uid);
  }
  public on(listener: (data: CashReturn[]) => void): Unsubscribe {
    return this._on(listener);
  }

}

export default CashReturnDB;