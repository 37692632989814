import { Unsubscribe } from "firebase/firestore";
import Sales from "../entities/sales.entity";
import FirestorePipe from "../utils/pipe";

class SalesDB extends FirestorePipe {

  public static readonly colName = 'Sales'

  constructor(idEstablishment: number, idEvent: number) {
    super(SalesDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<Sales[]> {
    return this._getAll<Sales>();
  }
  public get(uid: string): Promise<Sales> {
    return this._get(uid);
  }
  public on(listener: (data: Sales[]) => void): Unsubscribe {
    return this._on(listener);
  }

}

export default SalesDB;