import { Unsubscribe } from "firebase/firestore";
import Recharge from "../entities/recharge.entity";
import FirestorePipe from "../utils/pipe";

class RechargeDB extends FirestorePipe {

  public static readonly colName = 'Recharge'

  constructor(idEstablishment: number, idEvent: number) {
    super(RechargeDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<Recharge[]> {
    return this._getAll<Recharge>();
  }
  public get(uid: string): Promise<Recharge> {
    return this._get(uid);
  }
  public on(listener: (data: Recharge[]) => void): Unsubscribe {
    return this._on(listener);
  }

}

export default RechargeDB;