const jwtDecode = require('jwt-decode');
const { persistor } = require('../store/index');
export const TOKEN_KEY = '@UGET:token';
export const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if(token !== null) {
    const { iat } = jwtDecode(token);
    return iat > 1646911219;
  } else 
    return false;
};
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const userLogged = () => {
  const { data: user } = jwtDecode(localStorage.getItem(TOKEN_KEY));
  return user;
};
export const login = (token) => {
  if (token) localStorage.setItem(TOKEN_KEY, token);
};

export const logout = async () => {
  localStorage.clear();
  await persistor.purge();
};
