import { Unsubscribe } from "firebase/firestore";
import EntrancePay from "../entities/entrancesPay.entity";
import FirestorePipe from "../utils/pipe";

class EntrancePayDB extends FirestorePipe {

  public static readonly colName = 'EntrancesPay'

  constructor(idEstablishment: number, idEvent: number) {
    super(EntrancePayDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<EntrancePay[]> {
    return this._getAll<EntrancePay>();
  }
  public get(uid: string): Promise<EntrancePay> {
    return this._get(uid);
  }
  public on(listener: (data: EntrancePay[]) => void): Unsubscribe {
    return this._on(listener);
  }

}

export default EntrancePayDB;