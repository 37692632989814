import { Unsubscribe } from "firebase/firestore";
import Nfc from "../entities/nfc.entity";
import FirestorePipe from "../utils/pipe";

class NfcDB extends FirestorePipe {

  public static readonly colName = 'NFCs'

  constructor(idEstablishment: number, idEvent: number) {
    super(NfcDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<Nfc[]> {
    return this._getAll<Nfc>();
  }
  public get(uid: string): Promise<Nfc> {
    return this._get(uid);
  }
  public on(listener: (data: Nfc[]) => void): Unsubscribe {
    return this._on(listener);
  }

}

export default NfcDB;