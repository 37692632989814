// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5ccyHWoIfVa1lYeqlFfLvqge5kuiwYgw",
  authDomain: "uget-dev-5c2e1.firebaseapp.com",
  projectId: "uget-dev-5c2e1",
  storageBucket: "uget-dev-5c2e1.appspot.com",
  messagingSenderId: "1044720220046",
  appId: "1:1044720220046:web:835465c649f914037496df",
  measurementId: "G-H1LHJLR0D0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;