import { Unsubscribe } from "firebase/firestore";
import ActivationsNFC from "../entities/activationsNFC.entity";
import FirestorePipe from "../utils/pipe";

class ActivationsNFCDB extends FirestorePipe {

  public static readonly colName = 'ActivationsNFC'

  constructor(idEstablishment: number, idEvent: number) {
    super(ActivationsNFCDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<ActivationsNFC[]> {
    return this._getAll<ActivationsNFC>();
  }
  public get(uid: string): Promise<ActivationsNFC> {
    return this._get(uid);
  }
  public on(listener: (data: ActivationsNFC[]) => void): Unsubscribe {
    return this._on(listener);
  }

}

export default ActivationsNFCDB;