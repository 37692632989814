import { Unsubscribe } from "firebase/firestore";
import ThrowNfcCard from "../entities/throwNfcCard.entity";
import FirestorePipe from "../utils/pipe";

class ThrowNfcCardDB extends FirestorePipe {

  public static readonly colName = 'ThrowNfcCard'

  constructor(idEstablishment: number, idEvent: number) {
    super(ThrowNfcCardDB.colName, idEstablishment, idEvent);
  }

  public getAll(): Promise<ThrowNfcCard[]> {
    return this._getAll<ThrowNfcCard>();
  }
  public get(uid: string): Promise<ThrowNfcCard> {
    return this._get(uid);
  }
  public on(listener: (data: ThrowNfcCard[]) => void): Unsubscribe {
    return this._on(listener);
  }

}

export default ThrowNfcCardDB;